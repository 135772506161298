import type { FC, PropsWithChildren } from 'react';
import type { ViewConfiguration } from '../Page.types';

import React, { createContext } from 'react';

import { useStaticData } from '../../../context';

const defaultValue = {
  bitmovinLicense: '',
  bitmovinAnalyticsLicense: '',
};

if (typeof window === 'undefined') {
  React.useLayoutEffect = () => {};
}

export const PageContext = createContext<ViewConfiguration>(defaultValue);

export const PageProvider: FC<
  PropsWithChildren<{
    configurations?: ViewConfiguration;
  }>
> = ({ children, configurations = {} }) => {
  const { scope } = useStaticData();
  const currentScope = scope || configurations.scope || '';

  return (
    <PageContext.Provider value={configurations}>
      {currentScope && (
        <div className="container__scope-badge">{currentScope}</div>
      )}
      {children}
    </PageContext.Provider>
  );
};
